import React from "react";
import AnimationRevealPage from '../helpers/AnimationRevealPage.js';
import { Container, ContentWithPaddingXl } from '../components/misc/Layouts';
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { SectionHeading } from "../components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

function PrivacyPolicy ({ headingText = "Polityka prywatności" }) {
    return (
        <AnimationRevealPage>
            <Header />
            <Container>
                <ContentWithPaddingXl>
                    <HeadingRow>
                        <Heading>{headingText}</Heading>
                    </HeadingRow>
                    <Text>
                        <h2>Polityka prywatności</h2>
                        <p>Dokładamy wszelkich starań, aby zdjęcia, w tym wizerunki, umieszczane na stronie internetowej i w social mediach Duszpasterstwa/wspólnoty/parafii przetwarzane były zgodnie z prawem powszechnie obowiązującym, w tym z prawem autorskim i regulacjami dotyczącymi ochrony danych osobowych, a także zgodnie z prawem kanonicznym. W przypadku zauważenia nieprawidłowości w tym przedmiocie, prosimy o kontakt z Inspektorem Ochrony Danych Osobowych wyznaczonym przez Klasztor: <a href="mailto:odo.sluzew@dominikanie.pl">odo.sluzew@dominikanie.pl</a>.</p>
                    </Text>
                </ContentWithPaddingXl>
            </Container>
            <Footer />
        </AnimationRevealPage>
    );
};

export default PrivacyPolicy;
